var slugify = require('slugify')

const toSlug = text => {
	let slug = text

	while (slug.includes(':')) {
		slug = slug.replace(':', '')
	}

	return slugify(slug.toLowerCase())
}

export default toSlug
