import React from 'react'
// Imports from gatsby.
import { graphql } from 'gatsby'
import calendar from '@monorepo-amais/commons/images/calendar.png'
import PDF from '@monorepo-amais/commons/images/icon-pdf-positive.svg'
import Layout from '../components/layout'
import { BoletimDetalhe } from '@monorepo-amais/commons/components/boletins'

export default function BoletinsTemplate({ pageContext, data }) {
	const { allCosmicjsBoletinsMedicos } = data // There is also 'site' object in data.

	return (
		<BoletimDetalhe
			data={allCosmicjsBoletinsMedicos}
			index={pageContext.highligth}
			calendar={calendar}
			PDF={PDF}
			Layout={Layout}
		/>
	)
}

export const pageQuery = graphql`
	query($id: String!) {
		allCosmicjsBoletinsMedicos(filter: { id: { eq: $id } }) {
			nodes {
				id
				metadata {
					edicao
					data
					ano
					destaques {
						titulo
						subtitulo
						seo {
							titulo
							descricao
							imagem {
								imgix_url
							}
						}
						conteudo
						thumbnail {
							url
							imgix_url
						}
					}
					pdf {
						url
					}
				}
			}
		}
	}
`
