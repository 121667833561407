/* eslint-disable react/jsx-no-target-blank */
// TODO remover quando resolver unidades e voltar a utilizar tudo
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import MediaQuery from '@monorepo-amais/commons/components/mediaQuery'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import CarouselBoletimMobile from './CarouselBoletimMobile'

import { FacebookSquare } from 'styled-icons/fa-brands/FacebookSquare'
import { Linkedin } from 'styled-icons/fa-brands/Linkedin'
import { TwitterSquare } from 'styled-icons/fa-brands/TwitterSquare'
import { WhatsappSquare } from 'styled-icons/fa-brands/WhatsappSquare'
import SEO from '../seo'
import { BreadcrumbAuto, MainContainer } from '../Blocks'
import { formataDataCosmic } from '../../utils/date'

import { theme } from 'styled-tools'
import toSlug from '@monorepo-amais/commons/utils/slugify'
import useCreateElement from '../../hooks/useCreateElement'
import { testPageStatus } from '../../utils/region'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const Facebook = styled(FacebookSquare)`
	width: 25px;
	height: 25px;
	color: #707070;
	margin: 0px 0px 0px 10px;
`

const LinkedinIco = styled(Linkedin)`
	width: 25px;
	height: 25px;
	color: #707070;
	margin: 0px 0px 0px 10px;
`

const Twitter = styled(TwitterSquare)`
	width: 25px;
	height: 25px;
	color: #707070;
	margin: 0px 0px 0px 10px;
`

const Whatsapp = styled(WhatsappSquare)`
	width: 25px;
	height: 25px;
	color: #707070;
	margin: 0px 0px 0px 10px;
`

const LeftContent = styled.div`
	border-radius: 3px;
	border: solid 1px #e2e2e2;
	margin: 0px 2% 0px 0px;

	@media (max-width: 1023.98px) {
		margin: 0px 2% 0px;
	}
`

const LeftBorder = styled.div`
	width: 74%;

	@media (max-width: 1023.98px) {
		width: 100%;
	}
`

const RightBorder = styled.div`
	width: 24%;

	@media (max-width: 1023.98px) {
		margin: 40px 0px 0px;
		width: 100%;
	}
`

const RightForDesk = styled.div`
	width: fit-content;
	margin: 0 auto;

	@media (max-width: 1023.98px) {
		display: none;
	}
`

const RightContent = styled.div`
	border-radius: 3px;
	border: solid 1px #e2e2e2;
	padding: 20px 10px;

	@media (max-width: 1023.98px) {
		margin: 0 0 0 2%;
	}
`

const OtherHighlightContent = styled.div`
	margin: 0px 0px 10px;
`

const OtherHighlight = styled.span`
	font-size: 18px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.39;
	letter-spacing: normal;
	text-align: left;
	color: #464646;

	@media (max-width: 1023.98px) {
		font-size: 28px;
	}
`

const HighlightContainer = styled.div`
	margin: 0px 0px 40px;

	@media (max-width: 1023.98px) {
		margin: 0px 0px 40px;
	}
`

const HighlightDetail = styled.div`
	margin: 40px 20px 20px;

	@media (max-width: 1023.98px) {
		margin: 20px 20px;
	}
`

const BoletinHighlight = styled.span`
	font-size: 26px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.35;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.black_fonts};

	@media (max-width: 1023.98px) {
		font-size: 20px;
	}
`

const Underline = styled.div`
	color: ${theme('colors.black33')};
	height: 5px;
	width: 90px;
	margin-top: 5px;
	background: ${theme('colors.primary')};
`

const HighlightSubtitle = styled.span`
	font-size: 16px;
	font-weight: normal;
	font-style: italic;
	font-stretch: normal;
	line-height: 1.19;
	letter-spacing: normal;
	text-align: left;
	color: #707070;
`

const DetailContainer = styled.div`
	margin: 40px 0px;
`

const Line = styled.div`
	width: 100%;
	height: 0;
	border: solid 0.5px ${theme('colors.graye2')};
`

const LineRight = styled.div`
	width: 100%;
	height: 0;
	border: solid 0.5px ${theme('colors.graye2')};
	margin: 20px 0px;
`

const Calendar = styled.img`
	width: 22px;
	height: 22px;
	object-fit: contain;
`

const HighlightFooter = styled.div`
	width: 100%;
	margin: 20px 0px 0px;
`

const CalendarContent = styled.div`
	display: flex;
	align-items: center;
`

const HighlightFooterSession = styled.div`
	display: inline-block;

	@media (min-width: 630px) {
		width: 50%;
	}
`

const DateText = styled.span`
	font-size: 18px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.17;
	letter-spacing: normal;
	text-align: left;
	color: #707070;
	margin: 0px 10px 0px;
`

const MainContent = styled.div`
	display: flex;
	margin: 30px 0px 0px;

	@media (max-width: 1023.98px) {
		display: block;
	}
`

const HighlightThumb = styled.img`
	width: 100%;
	height: 150px;
	object-fit: cover;
	border-radius: 3px;
	margin: 0px 0px 20px;
`
const HighlightItem = styled.div`
	display: inline-block;
	border-top: none;
`

const HighlightTitle = styled.span`
	font-size: 16px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.31;
	letter-spacing: normal;
	text-align: left;
	color: ${theme('colors.black_fonts')};

	@media (max-width: 1023.98px) {
		font-size: 20px;
	}
`

const HighlightText = styled.span`
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.14;
	letter-spacing: normal;
	text-align: left;
	color: ${theme('colors.gray66')};
`

const HighlightTitleContainer = styled.div`
	margin: 0px 0px 10px;
`

const BoletinHeaderPDFText = styled.span`
	font-size: 20px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.3;
	letter-spacing: normal;
	text-align: left;
	color: ${theme('colors.primary')};
`

const HeaderPDF = styled.div`
	display: block;
	width: 225px;
	margin: 0 auto;
`

const PDFDownload = styled.div`
	display: flex;
	align-items: center;
`

const PDFIcon = styled.img`
	height: 22px;
	object-fit: contain;
	margin: 0px 10px 0px 0px;
`

const PDFLink = styled.a`
	text-decoration: none;
`

const Share = styled.div`
	float: right;
	display: flex;
	align-items: center;
`

const ShareLabel = styled.span`
	font-size: 18px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.17;
	letter-spacing: normal;
	text-align: left;
	color: #707070;
`

const WhatsappWeb = styled.a`
	@media (max-width: 1023.98px) {
		display: none;
	}
`

const OthersMobile = styled.div`
	@media (min-width: 1023.99px) {
		display: none;
	}
`

const WhatsappMobile = styled.a`
	@media (min-width: 1023.99px) {
		display: none;
	}
`

const HR = styled.div`
	width: 97%;
	height: 0;
	border: solid 0.5px ${theme('colors.graye2')};
	margin: 0 0 15px 0;
`

const BoletimDetalhe = ({ data, index, calendar, PDF, Layout }) => {
	const [, setOptions] = useCreateElement({
		rel: 'canonical',
		type: 'link'
	})
	useEffect(() => {
		const addCanonicalAfterTest = async () => {
			const href = window.location.origin + '/sp' + window.location.pathname.substr(3)
			const pageExistOnAmais = await testPageStatus(href)
			if (pageExistOnAmais)
				setOptions({
					href
				})
		}
		if (
			typeof window !== 'undefined' &&
			process.env.COSMIC_BUCKET.match(/^amais/g) &&
			!window.location.pathname.match(/\/sp/)
		) {
			addCanonicalAfterTest()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const refDetail = useRef(null)
	const highlight = data.nodes[0].metadata

	let formatedDate = formataDataCosmic(highlight.data)

	const mainDestaque = highlight.destaques[index]
	const mainIndex = index

	const titleOthers = (
		<OtherHighlightContent>
			<OtherHighlight>Outros destaques dessa edição</OtherHighlight>
		</OtherHighlightContent>
	)

	const PDFSession = (
		<>
			<HR />
			<HeaderPDF>
				<PDFLink href={highlight.pdf.url} target='_blank' download='proposed_file_name'>
					<PDFDownload>
						<PDFIcon src={PDF}></PDFIcon>
						<BoletinHeaderPDFText>Baixar a edição em PDF</BoletinHeaderPDFText>
					</PDFDownload>
				</PDFLink>
			</HeaderPDF>
		</>
	)

	let shareLink = ''

	if (typeof window !== 'undefined') {
		shareLink = window.location ? window.location.href : ''
	}

	const shareText = `${encodeURIComponent(mainDestaque.seo.titulo)}%0A%0A${encodeURIComponent(
		mainDestaque.seo.descricao
	)}%0A%0AConfira o material completo em:%0A%0A${shareLink}`

	const facebookBody = `https://www.facebook.com/sharer/sharer.php?u=${shareLink}`
	const linkedinBody = `https://www.linkedin.com/shareArticle/?mini=true&url=${shareLink}`
	const twitterBody = `https://twitter.com/intent/tweet?text=${shareText}`
	const whatsWebBody = `https://web.whatsapp.com/send?text=${shareText}`
	const whatsMobileBody = `https://api.whatsapp.com/send?text=${shareText}`

	const destaques = highlight.destaques.map((destaque, index) => {
		if (index !== mainIndex) {
			return (
				<div>
					<Link to={`/boletim-medico/${toSlug(destaque.titulo)}`}>
						<HighlightItem index={index}>
							<HighlightThumb src={imgixUrlOptimizerAuto(destaque.thumbnail.imgix_url)}></HighlightThumb>
							<HighlightTitleContainer>
								<HighlightTitle>{destaque.titulo}</HighlightTitle>
								<Underline />
							</HighlightTitleContainer>
							<HighlightText>{formatedDate}</HighlightText>
						</HighlightItem>
					</Link>
					<LineRight />
				</div>
			)
		} else {
			return null
		}
	})

	useEffect(() => {
		if (refDetail && refDetail.current) {
			const imgs = refDetail.current.getElementsByTagName('img')
			if (imgs && imgs[0]) {
				imgs[0].style.width = '100%'
			}
		}
	}, [])

	return (
		<Layout>
			<SEO
				title={highlight.destaques[index].seo.titulo}
				description={highlight.destaques[index].seo.descricao}
				image={imgixUrlOptimizerAuto(highlight.destaques[index].seo.imagem.imgix_url)}
			/>
			<MainContainer needPaddingZeroMax768={true}>
				<MediaQuery minDeviceWidth={1224}>
					<BreadcrumbAuto title={mainDestaque.titulo} />
				</MediaQuery>
				<MainContent>
					<LeftBorder>
						<LeftContent>
							<HighlightDetail>
								<HighlightContainer>
									<BoletinHighlight>{mainDestaque.titulo}</BoletinHighlight>
									<Underline />
								</HighlightContainer>
								<HighlightSubtitle>{mainDestaque.subtitulo}</HighlightSubtitle>
								<DetailContainer
									ref={refDetail}
									dangerouslySetInnerHTML={{ __html: mainDestaque.conteudo }}
								></DetailContainer>
								<Line></Line>
								<HighlightFooter>
									<HighlightFooterSession>
										<CalendarContent>
											<Calendar src={calendar}></Calendar>
											<DateText>{formatedDate}</DateText>
										</CalendarContent>
									</HighlightFooterSession>
									<HighlightFooterSession>
										<Share>
											<ShareLabel>Compartilhar:</ShareLabel>
											<a target='_blank' href={facebookBody}>
												<Facebook></Facebook>
											</a>
											<a target='_blank' href={linkedinBody}>
												<LinkedinIco></LinkedinIco>
											</a>
											<a href={twitterBody} target='_blank'>
												<Twitter></Twitter>
											</a>
											<WhatsappWeb href={whatsWebBody} data-action='share/whatsapp/share' target='_blank'>
												<Whatsapp></Whatsapp>
											</WhatsappWeb>
											<WhatsappMobile href={whatsMobileBody} data-action='share/whatsapp/share' target='_blank'>
												<Whatsapp></Whatsapp>
											</WhatsappMobile>
										</Share>
									</HighlightFooterSession>
								</HighlightFooter>
							</HighlightDetail>
						</LeftContent>
					</LeftBorder>
					<RightBorder>
						<RightContent>
							<OthersMobile>
								{titleOthers}
								<CarouselBoletimMobile
									listaDestaques={highlight.destaques}
									mainIndex={mainIndex}
									formatedDate={formatedDate}
								/>
								{PDFSession}
							</OthersMobile>
							<RightForDesk>
								{titleOthers}
								{destaques}
								{PDFSession}
							</RightForDesk>
						</RightContent>
					</RightBorder>
				</MainContent>
			</MainContainer>
		</Layout>
	)
}

export default BoletimDetalhe
