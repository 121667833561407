import React from 'react'
import { Link } from 'gatsby'
import Slider from 'react-slick'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import Imgix from 'react-imgix'
import toSlug from '@monorepo-amais/commons/utils/slugify'
import imgixUrlOptimizerAuto from '@monorepo-amais/commons/utils/imgixUrlOptimizerAuto'

const Carrossel = styled.div`
	margin: 0 0 40px 0;
	position: relative;
	z-index: 1;

	@media (min-width: 1024px) {
		display: none;
	}

	.slick-initialized {
		margin: 0;
		width: 100%;
		max-width: 100%;
		position: relative;

		.slick-list {
			padding: 0;
		}

		.slick-track {
			width: 2200px;
			opacity: 1;
			transform: translate3d(-550px, 0px, 0px);
			/* padding: 40px 0; */
		}

		.slick-cloned {
			width: 275px;
		}

		.slick-slide {
			float: left;
			height: 100%;
			min-height: 1px;
			outline: none;
			width: 275px;

			&.slick-active {
				.slick-text {
					opacity: 1;
					transition-property: opacity 0.4s;
					transition-duration: 0.4s;
					transition-timing-function: ease;
					transition-delay: 0.4s;
				}
			}

			.slick-text {
				opacity: 0;
			}
		}

		.slick-arrow {
			display: none !important;
			.slick-next {
				button {
					&:before {
						display: none;
					}
				}
			}

			.slick-prev {
				button {
					&:before {
						display: none;
					}
				}
			}
		}

		.slick-dots {
			position: absolute;
			bottom: 0;
			display: block;
			width: 100%;
			padding: 0 0 5px 0;
			margin: 0;
			list-style: none;
			text-align: center;

			li button {
				position: relative;

				&:before {
					color: ${theme('colors.grayb3')};
					font-size: 9px;
				}
			}

			.slick-active button {
				&:before {
					color: ${theme('colors.primary')};
				}
			}
		}
	}
`

const Mask = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 0;
`

// const HighlightThumb = styled.img`
// 	width: 100%;
// 	height: 150px;
// 	object-fit: cover;
// 	border-radius: 3px;
// 	margin: 0px 0px 20px;
// `

const HighlightItem = styled.div`
	display: inline-block;
	border-radius: 3px;
	border: 1px solid ${theme('colors.graye2')};
	width: 94%;
`

const HighlightTitle = styled.span`
	font-size: 16px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.31;
	letter-spacing: normal;
	text-align: left;
	color: ${theme('colors.black_fonts')};

	@media (max-width: 1023.98px) {
		font-size: 20px;
	}
`

const HighlightText = styled.span`
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.14;
	letter-spacing: normal;
	text-align: left;
	color: ${theme('colors.gray66')};
	padding: 0 0 0 8px;
`

const Underline = styled.div`
	color: ${theme('colors.black33')};
	height: 5px;
	width: 90px;
	margin-top: 5px;
	background: ${theme('colors.primary')};
`

const HighlightTitleContainer = styled.div`
	margin: 0px 0px 10px;
	padding: 8px 0 0 8px;
`

const ContainerCustom = styled.div`
	padding-top: 20px;
	/* background-color: ${theme('colors.grayf8')}; */
	.slick-dots li button {
		&:before {
			color: ${theme('colors.mainColor')};
			font-size: 10px;
		}
	}
	@media (max-width: 767.98px) {
		.slick-arrow {
			display: none !important;
		}
		.container {
			padding-right: 0;
			padding-left: 0;
		}
		.slick-arrow {
			display: none !important;
		}
	}
`

const CarouselBoletimMobile = ({ listaDestaques, mainIndex, formatedDate }) => {
	const settings = {
		centerMode: true,
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerPadding: 16
	}

	return (
		<div className='slick-track'>
			<Carrossel>
				<Mask />
				<ContainerCustom>
					<Slider {...settings}>
						{listaDestaques &&
							listaDestaques.map((destaque, index) => {
								if (index !== mainIndex) {
									return (
										<Link to={`/boletim-medico/${toSlug(destaque.titulo)}`}>
											<HighlightItem index={index}>
												<Imgix
													src={imgixUrlOptimizerAuto(destaque.thumbnail.imgix_url)}
													width='100%'
													height={193}
													imgixParams={{ ar: '2:1', fit: 'crop' }}
												/>
												<HighlightTitleContainer>
													<HighlightTitle>{destaque.titulo}</HighlightTitle>
													<Underline />
												</HighlightTitleContainer>
												<HighlightText>{formatedDate}</HighlightText>
											</HighlightItem>
										</Link>
									)
								} else {
									return null
								}
							})}
					</Slider>
				</ContainerCustom>
			</Carrossel>
		</div>
	)
}

export default CarouselBoletimMobile
